@media only screen and (max-width: 767px) {
 .video-area{
    width: auto;
    z-index: 99999;
 }
 .home-video{
    width: auto;
    min-height: 90vh;
 }
 .marque-text{
    font-size: 1.2rem;
 }
 img{
    max-width: 100%;

 }
 .button-type-two{
    width: 100%;
 }
 .footer-main{
    height: 100vh;
    padding: 2vh;
 }
 .bg-build-contact{
    height: 132vh;
 }
 .text-main-center h1{
   font-size: 19px;
 }
 .bg-build {
background-size: contain; }
 .text-main-center .button-type-two{
   font-size: 17px;
 }
  }
  @media only screen and (max-width: 660px) {
   .home-video{
      width: auto;
      min-height: 90vh;
   }
   }

   @media only screen and (max-width: 992px) and (min-width:767px) {
      .text-main-center-blog{
         left: 30%;
      }
      .bg-build {
         background-size: contain; }
      }


   @media only screen and (max-width: 1445px) and (min-width:992px) {
     .responsive-img-style{
      height: 100%;
      max-width: 100%;
padding: 0vh 1vh;
     } 
   } 