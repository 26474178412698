body {
  background-color: #2a3543;
}
#root {
  background-color: #2a3543;
}
:root {
  background-color: #2a3543 !important;
}

.bg-color {
  overflow: hidden;
}

.tower-area {
  height: 100vh;
  gap: 0.5rem;
  animation: scaleing 2s 2s 1 forwards;
}

@keyframes scaleing {
  0% {
    transform: scale(1);
    gap: 0.5rem;
  }
  100% {
    transform: scale(5);
    gap: 20rem;
  }
}
.header-main {
  z-index: 99;
  background-color: #2a3543;
}
.video-area {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-size: 24px;
  width: max-content;
}
.button-type {
  background-color: #f6f6f6bd;
  font-size: 20px;
  border: none;
  border-radius: 10px;
  width: 230px;
  color: black !important;
}
.section-bg {
  background-color: #f6f6f62e;
  border-radius: 12px;
}
.marque-text {
  font-size: 3rem;
  font-weight: 500;
}
.text-main-center {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.text-main-img {
  max-width: 100%;
  filter: brightness(0.7);
  border-radius: 10px;
}
.responsive-img-style {
  height: 100%;
  max-width: 100%;
}
.text-main-center-blog {
  bottom: 17%;
  left: 12%;
  position: absolute;
  transform: translate(-5%, -5%);
}

.bg-build {
  background-image: url(../src/assets/img/build.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-build-contact {
  background-image: url(../src/assets/img/build.png);
  background-size: auto;
  height: 100vh;
  background-position: top;
  background-repeat: no-repeat;
}
.button-type-two {
  background-color: transparent;
  font-size: 20px;
  border: 1px solid white;
  border-radius: 10px;
  width: 230px;
  color: white;
}
.footer-text {
  list-style: none;
  padding: 0;
}
.footer-main {
  background-color: #212934;
  height: 50vh;
  justify-content: center;
  align-items: center;
}
.footer-text li,
span {
  color: #f6f6f6;
  font-weight: 300;
  margin-bottom: 3px;
}

.footer-text h6 {
  line-height: inherit;
}
.footer-main .col-8 {
  border-bottom: 1px solid white;
}
.footer-main .row > * {
  padding: 0 !important;
}
a {
  color: white !important;

  text-decoration: none !important;
}

.modal-header {
  border-bottom: none !important;
}
.modal-fullscreen .modal-content {
  background: transparent;
  backdrop-filter: blur(17px);
}
.btn-close {
  color: white !important;
}
.bg-build-contact iframe {
  border-radius: 10px;
}
.modal-fullscreen .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-img-style {
  border-radius: 10px;
}

/* .modal-dialog {
  transition: transform 0.5s ease-in-out !important;
} */

.btn-close {
  --bs-btn-close-bg: url(../src/assets/img/close.png) !important;
}
.home-video {
  width: 100%;
}

.content {
  cursor: pointer;
  transition: 0.5s;
}

.sakin-main:hover .content {
  transform: translateY(-20px);
}

.sakin-main .button-type-two {
  position: absolute;
  top: 70%;
}

.sakin-main .button-type-two {
  transform: translateY(40px);
  transition: 0.5s;
  opacity: 0;
}

.sakin-main:hover .button-type-two {
  transform: translateY(0px);
  opacity: 1;
}

#checkbox2 {
  display: none;
}

.toggle2 {
  /* position: relative;  */
  position: absolute;
  top: 32px;
  right: 50px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition-duration: 0.5s;
}

.bars {
  width: 60%;
  height: 2px;
  background-color: #fff;
  border-radius: 6px;
}

.bar2 {
  transition-duration: 0.8s;
}

.bar1,
.bar3 {
  width: 60%;
}

#checkbox2:checked + .toggle2 .bars {
  position: absolute;
  transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 .bar2 {
  transform: scaleX(0);
  transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 .bar1 {
  width: 80%;
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 .bar3 {
  width: 80%;
  transform: rotate(-45deg);
  transition-duration: 0.5s;
}

#checkbox2:checked + .toggle2 {
  transition-duration: 0.5s;
  transform: rotate(180deg);
}

.active {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 110px;
  z-index: 20;
  gap: 12px;
  width: 100vw !important;
  left: 0;
  height: 20vh;
  background: rgba(16, 16, 16, 0.7);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8.3px);
  -webkit-backdrop-filter: blur(8.3px);
  border: 1px solid rgba(16, 16, 16, 0.31);
  overflow: hidden !important;
  /* animation: mobileMenu 0.5s ease-in-out forwards;  */
}

.active-open {
  animation: mobileMenuOpen 0.4s ease-in-out forwards;
}

.active-close {
  animation: mobileMenuClose 0.4s ease-in-out forwards;
}
@keyframes mobileMenuOpen {
  0% {
    height: 0vh;
  }
  100% {
    height: 20vh;
  }
}
@keyframes mobileMenuClose {
  0% {
    height: 20vh;
  }
  100% {
    height: 0vh;
  }
}

@media (max-width: 500px) {
  .toggle2 {
    top: 40px;
    right: 25px;
  }
}
.sakin-style:hover img {
  scale: 1.2;
}
.radius-img {
  border-radius: 12px;
}
.residents-card {
  position: relative;
  overflow: hidden;
  border: 1px solid white;
  border-radius: 10px;
}

.sakin-style {
  transition: transform 0.3s ease;
  transform: scale(1);
  &:hover {
    transform: scale(1.05);
  }
}
.active-link {
  border-bottom: 1px solid white;
}
.header-main p {
  margin-bottom: 0rem !important;
}
